import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  inject,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppAuthService } from 'features/auth/services/app-auth.service';

@Directive({
  selector: '[fidNotAuthenticated]',
  standalone: true,
})
export class NotAuthenticatedDirective implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private templateRef: TemplateRef<unknown> = inject(TemplateRef);
  private viewContainer: ViewContainerRef = inject(ViewContainerRef);
  private appAuth: AppAuthService = inject(AppAuthService);
  private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  public ngOnInit(): void {
    this.viewContainer.clear();

    this.appAuth
      .isAuthenticated()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isAuthenticated: boolean) => {
        this.viewContainer.clear();

        if (!isAuthenticated) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }

        this.changeDetectorRef.detectChanges();
      });
  }
}
